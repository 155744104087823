// import logo from "./logo.svg";
import "./App.css";
import About from "./component/About/About";
import Hero from "./component/Hero/Hero";
import NewRelease from "./component/NewRelease/NewRelease";
import PreviousEdition from "./component/PreviousEdition/PreviousEdition";
// import { useLocation } from "react-router-dom";

import Navbar from "./component/Navbar/Navbar";
import { Subscriber } from "./component/Subscriber/Subscriber";
import { Footer } from "./component/Footer/Footer";
import { ScrollToTop } from "./utils/ScrollToTop";
// import { useEffect } from "react";

// import Slider1 from "./assets/images/sliderImg2.1.png";

function App() {
  // const location = useLocation();

  // const { pathname } = location;
  // useEffect(() => {
  //   console.log(pathname);
  // }, []);

  return (
    <>
      <ScrollToTop />
      <head>
        <title>Upwealth magazine</title>
      </head>
      <div className="App">
        <Navbar />
        <div id="heroSEC">
          <Hero />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="newrelease">
          <NewRelease />
        </div>
        <div id="previousedition">
          <PreviousEdition />
        </div>

        <div id="subscriberBenifits">
          <Subscriber />
        </div>
        <section id="footerSec">
          <Footer />
        </section>
      </div>
    </>
  );
}

export default App;
