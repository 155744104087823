import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginRequest, loginSuccess, loginFailure } from "../../Redux/userSlice";
import "react-toastify/dist/ReactToastify.css";

import "./Login.scss";
import LoginCardBg from "../../assets/images/LoginCardBg.png";
import Logo from "../../assets/images/logo.png";
import LoginEyeIcon from "../../assets/images/LoginEyeIcon.png";
import GoBackArrowIcon from "../../assets/images/downArrowIcon.png";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hideShowPass, setHideShowPass] = useState({
    state: false,
    whichInput: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleHideAndShowPass = (e, inputName) => {
    setHideShowPass({
      state: !hideShowPass.state,
      whichInput: inputName,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const credentials = { email, password };

    try {
      dispatch(loginRequest()); // Dispatch login request action (loading state)
      const response = await fetch("http://localhost:5000/api/users/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      dispatch(loginSuccess(data));
      toast.success("Login successful!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
      // Handle successful login (e.g., store JWT, redirect)
      console.log("Login successful:", data);
    } catch (err) {
      dispatch(loginFailure(err.message)); // Dispatch login failure action with error message
      setError(err.message);
      toast.error(err.message); // Show error message as toast
    }
  };

  return (
    <>
      <motion.button
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "backInOut" }}
        className="Login__goBackBTN"
        onClick={() => navigate("/")}
      >
        <img src={GoBackArrowIcon} alt="go-back-arrow" />
        <span>Go Back</span>
      </motion.button>
      <div style={{ overflow: "hidden" }} className="Login__outterWrapper">
        <motion.div
          initial={{
            opacity: 0,
            y: 200,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.6,
            ease: "backInOut",
          }}
          className="Login__mainFormWrapper"
        >
          <div className="Login__cardWrapper">
            <img
              className="Login__CardBg"
              src={LoginCardBg}
              alt="Login-card-img"
            />
            <Link to="/">
              <img className="Login__CardLogo" src={Logo} alt="logo" />
            </Link>

            <div className="Login__CardTextWrapper">
              <h1>Welcome to Upwealth Magazine</h1>
            </div>
          </div>
          <div className="Login__FormWrapper">
            <h2 className="Login__FormTitle">Login</h2>

            <form onSubmit={handleSubmit}>
              <div className="Login__FormInputsMainWrapper">
                <div className="Login__FormInputsWrapper">
                  <div>
                    <input
                      type="email"
                      placeholder="Email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type={
                        hideShowPass.state && hideShowPass.whichInput === "Password"
                          ? "text"
                          : "password"
                      }
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <img
                      onClick={(e) => handleHideAndShowPass(e, "Password")}
                      className="Login__InputEyeIcon"
                      src={LoginEyeIcon}
                      alt="eye-icon"
                    />
                  </div>
                </div>
                {error && <p
                  style={{
                    fontSize: '16px', 
                    color: 'red',     
                    marginTop: '15px',
                  }}
                >{error}</p>}
                <button type="submit" className="Login__SubscribeBtn">Log In</button>
              </div>
            </form>
        
            <ToastContainer style={{ fontSize: "14px" }} />
            <div className="Login__LoginOptions">
              <h4>
                Don’t have a subscription?{" "}
                <Link className="Login__loginLink" to="/register">
                  Subscribe
                </Link>
              </h4>

              <div className="Login__socialMediaOptions">
                <h4>Or subscribe with</h4>
                <div className="Login__SocialMediaMainWrapper">
                  <div className="Login__socialMediaIconWrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                    </svg>
                  </div>

                  <div className="Login__socialMediaIconWrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.9 0-184.9z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};
