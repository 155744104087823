import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./NewRelease.scss";
import { Link } from "react-router-dom";

// import NewReleaseBg from "../../assets/images/newRelease.png";
import NewReleaseCardImg from "../../assets/images/newReleaseCardImg.png";
import DownArrowIcon2 from "../../assets/images/downArrowIcon2.png";

import NewReleaseBg2 from "../../assets/images/NewReleaseBg2.png";
import NewReleaseBg3 from "../../assets/images/NewReleaseBg3.png";
import { useSelector } from "react-redux";

//  the delay of the subscription text and change the font for the first text

const NewRelease = () => {
  const navigate = useNavigate();
  const showTextRef = useRef(null);
  const [showText, setShowText] = useState(false);
  const { userInfo} = useSelector((state) => state.user);
  // const User = useSelector((state) => state.user.userInfo);

  // Images
  const newReleaseImg = "https://res.cloudinary.com/dikekyyr9/image/upload/v1726262971/8-Ed7_Celebrating_Fathers_lxccsw.jpg"


  useEffect(() => {
    let scrollPositionY;

    window.addEventListener("scroll", () => {
      scrollPositionY = window.scrollY;

      if (window.scrollY >= 1245) {
        const clickInterval = setTimeout(handleShowText, 5000);
      } else if (window.scrollY <= 1200) {
        setShowText(false);
      }
    });

    const handleShowText = () => {
      setShowText(true);
    };
  }, []);

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: -150,
        x: -150,
        scale: 0,
      }}
      whileInView={{ opacity: 1, y: 0, x: 0, scale: 1 }}
      transition={{ duration: 1.2, ease: "backInOut" }}
      viewport={{ once: true }}
      className="NewRelease__containerWrapper"
    >
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
        }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, ease: "backOut", delay: 2.5 }}
        className="newReleaseText__bg"
      >
        <div className="newRelease__Marquee__GP">
          <span>NEW RElEASE</span>
          <span>NEW RElEASE</span>
          <span>NEW RElEASE</span>
        </div>
        <div className="newRelease__Marquee__GP">
          <span>NEW RElEASE</span>
          <span>NEW RElEASE</span>
          <span>NEW RElEASE</span>
          <span>NEW RElEASE</span>
        </div>
      </motion.div>

      <motion.img
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 0.2 }}
        transition={{ duration: 1.2, ease: "backInOut", delay: 1.7 }}
        className="newReleaseText__bg2"
        src={NewReleaseBg2}
      />
      <motion.img
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 0.2 }}
        transition={{ duration: 1.2, ease: "backInOut", delay: 1.7 }}
        className="newReleaseText__bg3"
        src={NewReleaseBg3}
      />

      <div className="NewRelease__contentMainWrapper">
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "backOut", delay: 1.5 }}
          className="NewRelease__titleAndSubTitleWrapper"
        >
          <div className="NewRelease__tileAndAubInnerWrapper">
            <div className="NewRelase__SubtitleWrapper">
              <h2>New Release</h2>
            </div>
            <div className="NewRelase__titleAndSubSeprator" />
            <div className="NewRelease__titleWrapper">
              <p>
                This is the place to start or continue to grow and scale your
                business!
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "backOut", delay: 1.5 }}
          className="NewRelease__contentImageWrapper"
        >
          <div className="NewRelease__ImageWrapper">
            <img src={newReleaseImg} alt="new-release-cardImg" />

            <div className="NewRelease__rotateDiv"></div>
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "backOut", delay: 1.5 }}
          className="NewRelease__textContentWrapper"
        >
          <h3 className="NewRelease__subTitle">New Release</h3>
          <div className="NewRelase__titleWrapper">
            <h2 className="NewRelease__title">
              This is the place to start or continue to grow and scale your
              business!
            </h2>
          </div>

          <div
            tabIndex="0"
            ref={showTextRef}
            className={`NewRelease__infoWrapper ${
              showText ? "NewRelease__infoWrapperPseudo" : ""
            }`}
          >
            <p>“If your heart is in your dream, no request is too extreme”</p>
            <h3>-Jiminy Cricket.</h3>
            <p>
              Subscribe today and learn how to bring your dreams to life and
              grow your profit margins.
            </p>
          </div>
          <div className="NewRelease__buttonWrapper">
            {!userInfo ? (
              <button
                onClick={() => navigate("/register")}
                className="NewRelease__subscribeUpwealth"
              >
                Subscribe to Upwealth!
              </button>
            ) : (
              <Link
              target="_blank"
                to="https://myflip.kaltechconsultancyservices.com/june-august.html"
                className="NewRelease__subscribeUpwealth"
              >
                View Magazine
              </Link>
            )}
            <a href="#previousedition" className="moveToNewSec__Btn">
              <img src={DownArrowIcon2} alt="downArrowIcon" />
            </a>
          </div>
        </motion.div>

        <h2 className="NewRelase__SubtitleMob">New Release</h2>

        <h2 className="NewRelease__titleMob">
          This is the place to start or continue to grow and scale your
          business!
        </h2>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "backOut", delay: 1.5 }}
          className="NewRlease__BTNWrapper"
        >
          {!userInfo ? (
            <button
              onClick={() => navigate("/register")}
              className="NewRelease__subscribeUpwealth"
            >
              Subscribe to Upwealth!
            </button>
          ) : (
            <Link target="_blank"
              to="https://myflip.kaltechconsultancyservices.com/may_edition.html"
              className="NewRelease__subscribeUpwealth"
            >
              View Magazine
            </Link>
          )}
          <a href="#previousedition" className="moveToNewSec__Btn">
            <img src={DownArrowIcon2} alt="downArrowIcon" />
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default NewRelease;
