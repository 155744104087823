import React, { useEffect } from "react";
import "./Footer.scss";
import { FooterTwo } from "../FooterTwo/FooterTwo";

import { motion, useAnimate } from "framer-motion";

import FooterCard1 from "../../assets/images/FooterCard1.png";
import FooterCard2 from "../../assets/images/FooterCard2.png";
import FooterCard3 from "../../assets/images/FooterCard3.png";
import FooterCard4 from "../../assets/images/FooterCard4.png";
import PaperPlaneIcon from "../../assets/images/PaperPlaneIcon.png";
import PaperPlaneBl from '../../assets/images/PaperPlaneBl.png'
import DownArrowB from "../../assets/images/DownArrowB.png";
import { useSelector } from "react-redux";

const FooterCardOne = () => {
  const [scope, animate] = useAnimate();

  const handleMouseHover = () => {
    animate(
      ".Footer__Card1",
      {
        x: "-25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: "25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  const handleMouseLeave = () => {
    animate(
      ".Footer__Card1",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  return (
    <div
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseLeave}
      ref={scope}
      className="Footer__cardOneWrapper"
    >
      <motion.img
        className="Footer__Cards Footer__Card1"
        alt="card-1"
        src={FooterCard1}
      />
      <motion.img
        className="Footer__Cards Footer__Card2"
        alt="card-2"
        src={FooterCard2}
      />
    </div>
  );
};
const FooterCardTwo = () => {
  const [scope, animate] = useAnimate();

  const handleMouseHover = () => {
    animate(
      ".Footer__Card1",
      {
        x: "25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: "-25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  const handleMouseLeave = () => {
    animate(
      ".Footer__Card1",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  return (
    <div
      ref={scope}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseLeave}
      className="Footer__cardTwoWrapper"
    >
      <img
        className="Footer__Cards Footer__Card1"
        src={FooterCard3}
        alt="card-3"
      />
      <img
        className="Footer__Cards Footer__Card2"
        src={FooterCard4}
        alt="card-4"
      />
    </div>
  );
};

const FooterCardOneTab = () => {
  const [scope, animate] = useAnimate();

  const handleMouseHover = () => {
    animate(
      ".Footer__Card1",
      {
        x: "-25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: "25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  const handleMouseLeave = () => {
    animate(
      ".Footer__Card1",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  return (
    <div
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseLeave}
      ref={scope}
      className="Footer__cardOneWrapperTab"
    >
      <motion.img
        className="Footer__Cards Footer__Card1"
        alt="card-1"
        src={FooterCard1}
      />
      <motion.img
        className="Footer__Cards Footer__Card2"
        alt="card-2"
        src={FooterCard2}
      />
    </div>
  );
};

const FooterCardTwoTab = () => {
  const [scope, animate] = useAnimate();

  const handleMouseHover = () => {
    animate(
      ".Footer__Card1",
      {
        x: "25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: "-25%",
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  const handleMouseLeave = () => {
    animate(
      ".Footer__Card1",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );

    animate(
      ".Footer__Card2",
      {
        x: 0,
      },
      {
        duration: 0.3,
        ease: "backInOut",
      }
    );
  };

  return (
    <div
      ref={scope}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseLeave}
      className="Footer__cardTwoWrapperTab"
    >
      <img
        className="Footer__Cards Footer__Card1"
        src={FooterCard3}
        alt="card-3"
      />
      <img
        className="Footer__Cards Footer__Card2"
        src={FooterCard4}
        alt="card-4"
      />
    </div>
  );
};

export const Footer = () => {
  const [scope, animate] = useAnimate();
  const User = useSelector((state) => state?.user.userInfo);

  const handleAnimation = async () => {
    animate(
      "#footer__Subcribe",
      {
        fontFamily: "Sans-Serif",
        color: "white",
        y: [-50, 0],
        opacity: [0, 1],
      },
      {
        duration: 0.6,
        ease: "backInOut",
        delay: 0.8,
      }
    );

    await animate(
      "#footer__downArrowImg",
      {
        y: [-50, 0],
        opacity: [0, 1],
        rotate: "-90deg",
      },
      {
        duration: 0.6,
        ease: "backInOut",
        delay: 0.8,
      }
    );

    animate(
      "#footer__downArrowImg",
      {
        rotate: "0deg",
      },
      {
        duration: 0.6,
        ease: "backInOut",
        delay: 0.9,
      }
    );

    await animate(
      "#footer__Subcribe",
      {
        y: 0,
        opacity: 1,
      },
      {
        duration: 0.6,
        ease: "backInOut",
        delay: 0.9,
      }
    );
    animate(
      "#footer__Subcribe",
      {
        fontFamily: "Montserrat",
        color: "black",
      },
      {
        duration: 0.6,
        ease: "backInOut",
        delay: 0.9,
      }
    );
  };

  useEffect(() => {
    handleAnimation();

    // let scrollPosition = 0;

    window.addEventListener("scroll", () => {
      // scrollPosition = window.scrollY;

      if (window.scrollY >= 3274 && window.scrollY <= 3280) {
        handleAnimation();
      }
    });
  }, []);

  return (
    <>
      <footer className="Footer__outterWrapper">
        <div className="Footer__topContentWrapper">
          <div className="Footer__topWrapper">
            <FooterCardOne />

            <div className="Footer__cardsWrapper">
              <FooterCardOneTab />
              <FooterCardTwoTab />
            </div>


            <div className="Footer__contentWrapper">
              <div ref={scope} className="Footer__subscribeTextAndImgWrapper">
                <h2 id="footer__Subcribe">{User?.status ? 'Subscribe to our Newsletter' : 'Subscribe to Upwealth magazine'}</h2>
                <motion.img
                  id="footer__downArrowImg"
                  src={DownArrowB}
                  alt="arrow-icon"
                />
              </div>
              {
                !User?.status ?
                  <p>and let the pages of inspiration unleash the power wealth.</p>
                  : <p>Get Timely Updates From Our Top Magazine.</p>
              }

              <div className="Footer__inputAndBtnWrapper">
                <input
                  className="Footer__emailInput"
                  placeholder="Enter email"
                  type="text"
                />

                <button className="Footer__subscribeBTN1">
                  <span>Subscribe</span>
                  <img src={PaperPlaneIcon} alt="paper-planeIcon" />
                </button>
                <button className="Footer__subscribeBTN2">
                  <span>Subscribe</span>
                  <img src={PaperPlaneBl} alt="paper-planeIcon" />
                </button>
              </div>
            </div>

            <FooterCardTwo />
          </div>
        </div>
        <FooterTwo secID='heroSEC' />
      </footer>
    </>
  );
};
