// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null, // Store user information here
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.userInfo = action.payload; // Save user info in Redux state
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload; // Save error message
    },
    logout(state) {
      state.userInfo = null; // Clear user info on logout
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout } = userSlice.actions;

export default userSlice.reducer;
