
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Subscribe.scss";
import { AnimatePresence, motion, useAnimate } from "framer-motion";

import PhoneIcon from "../../assets/images/SubscribePhoneIcon.png";
import GraphIcon from "../../assets/images/GraphIcon.png";
import NotebookIcon from "../../assets/images/NotebookIcon.png";
import PaperPlaneIcon from "../../assets/images/PaperPlaneIcon.png";
import IphoneImg from "../../assets/images/IphoneImg.png";
import TabletImg from "../../assets/images/TabletImg.png";
import MacbookImg from "../../assets/images/macbookImg.png";
import DownArrowIcon from "../../assets/images/downArrowIcon.png";
import { useSelector } from "react-redux";

const TextAnimation = ({ textToAnimate }) => {
  const words = textToAnimate.split(" ");
  return (
    <>
      {words.map((cur, id) => (
        <motion.span
          key={id}
          style={{ display: "inline", overflow: "hidden" }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: id * 0.16, duration: 0.9 }}
        >
          {cur}{" "}
        </motion.span>
      ))}
    </>
  );
};

export const Subscriber = () => {
  const [scope, animate] = useAnimate();
  const navigate = useNavigate();
  const User = useSelector((state) => state.user.userInfo);

  const animateImges = async () => {
    animate(
      "#IphoneImgAnimate",
      {
        x: "97%",
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#TabletImgAnimate",
      {
        x: "-17%",
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    await animate(
      "#macBookImgAnimate",
      {
        y: [0, 15, 15, 0],
      },
      {
        duration: 1.9,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#TabletImgAnimate",
      {
        opacity: 0,
        x: "25%",
      },
      {
        duration: 1,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    await animate(
      "#IphoneImgAnimate",
      {
        scale: 1.7,
        x: "30%",
        y: "-45%",
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#IphoneImgAnimate",
      {
        opacity: 0,
        scale: 1,
        x: "30%",
      },
      {
        duration: 1,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    await animate(
      "#TabletImgAnimate",
      {
        scale: 1.5,
        x: "40%",
        y: "-30%",
        opacity: 1,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#TabletImgAnimate",
      {
        scale: 1,
        opacity: 0,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    await animate(
      "#macBookImgAnimate",
      {
        scale: 1.3,
        x: "0%",
        opacity: 1,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    await animate(
      "#macBookImgAnimate",
      {
        scale: 1,
        x: 0,
        opacity: 1,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#TabletImgAnimate",
      {
        x: 0,
        y: 0,
        opacity: 1,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );

    animate(
      "#IphoneImgAnimate",
      {
        x: 0,
        y: 0,
        opacity: 1,
      },
      {
        duration: 1.3,
        times: 1,
        ease: "backInOut",
        delay: 0.2,
      }
    );
  };

  useEffect(() => {
    animateImges();
    const timer = setInterval(() => animateImges(), 14000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="Subscribe__outterWrapper">
      <div className="Subscribe__mainContainerWrapper">
        <h2 className="Subscribe__subTitle">Subscriber benifits</h2>
        <motion.div
          initial={{ x: -250, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.3, delay: 0.6, ease: "backInOut" }}
          viewport={{ once: true }}
          className="Subscribe__leftWrapper"
        >
          <h3 className="Subscribe__leftWrapperSubTitle">
            Subscriber benifits
          </h3>
          <h2 className="Subscribe__leftWrapperTitle">
            {/* Stay updated with our latest articles and exclusive content. */}
            <TextAnimation textToAnimate="Stay updated with our latest articles and exclusive content." />
          </h2>

          <div className="Subscribe__animatedCardWrapper">
            {
              !User?.status ? (
                <>
                  <motion.div
                    initial={{ y: -80, opacity: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, ease: "backOut" }}
                    className="Subscribe__animatedCard"
                  >
                    <div className="Subscribe__IconWrapper">
                      <img src={PhoneIcon} alt="phone icon" />
                    </div>
                    <p className="Subscribe__animatedCardPara">
                      Free Monthly Digital Magazine Subscription
                    </p>
                  </motion.div>

                  <motion.div
                    initial={{ y: -80, opacity: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, ease: "backOut", delay: 0.6 }}
                    className="Subscribe__animatedCard"
                  >
                    <div className="Subscribe__IconWrapper">
                      <img src={GraphIcon} alt="phone icon" />
                    </div>
                    <p className="Subscribe__animatedCardPara">
                      Weekly Business Growth Strategies & Tips
                    </p>
                  </motion.div>

                  <motion.div
                    initial={{ y: -80, opacity: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, ease: "backOut", delay: 1.2 }}
                    className="Subscribe__animatedCard"
                  >
                    <div className="Subscribe__IconWrapper">
                      <img src={NotebookIcon} alt="phone icon" />
                    </div>
                    <p className="Subscribe__animatedCardPara">
                      Free Digital Gratitude Journal
                    </p>
                  </motion.div>
                </>
              ) : (<motion.div
                initial={{ y: -80, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.9, ease: "backOut", delay: 1.2 }}
                className="Subscribe__animatedCard"
              >
                <div className="Subscribe__IconWrapper">
                  <img src={NotebookIcon} alt="phone icon" />
                </div>
                <p className="Subscribe__animatedCardPara">
                  Free Digital Gratitude Journal
                </p>
              </motion.div>)
            }
          </div>

          <AnimatedCards />

          {!User?.status && (
            <button
              onClick={() => navigate("/register")}
              style={{ cursor: "pointer" }}
              className="Subscribe__expandBtn"
            >
              <span>Subscribe To Upwealth</span>
              <img src={PaperPlaneIcon} alt="paper-planeIcon" />
            </button>
          )}
        </motion.div>

        <motion.div
          ref={scope}
          initial={{ x: 250, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.9, delay: 0.6, ease: "backInOut" }}
          viewport={{ once: true }}
          className="Subscribe__rightWrapper"
          onMouseEnter={animateImges}
        >
          <img
            id="macBookImgAnimate"
            className="macbookImg"
            src={MacbookImg}
            alt="macbookImg"
          />
          <img
            id="TabletImgAnimate"
            className="tabletImg"
            src={TabletImg}
            alt="macbookImg"
          />
          <img
            id="IphoneImgAnimate"
            className="IphoneImg"
            src={IphoneImg}
            alt="macbookImg"
          />
        </motion.div>

        {!User?.status && (
          <button
            onClick={() => navigate("/register")}
            style={{ cursor: "pointer" }}
            className="Subscribe__expandBtn"
          >
            <span>Subscribe To Upwealth</span>
            <img src={PaperPlaneIcon} alt="paper-planeIcon" />
          </button>
        )}
      </div>
      <a
        href="#footerSec"
        className="moveToNewSec__Btn"
        aria-description="move-to-the-next-section-button"
      >
        <img src={DownArrowIcon} alt="downArrowIcon" />
      </a>
    </div>
  );
};

const AnimatedCards = () => {
  const [visiableCtxIndex, setVisiableCtxIndex] = useState(0);
  const User = useSelector((state) => state?.user?.userInfo);

  const contentList = [
    {
      title: "Free Monthly Digital Magazine Subscription",
      icon: PhoneIcon,
    },
    {
      title: "Weekly Business Growth Strategies & Tips",
      icon: GraphIcon,
    },
    {
      title: "Free Digital Gratitude Journal",
      icon: NotebookIcon,
    },
  ];
  const contentListAfterLoggIn = [
    {
      title: "Free Digital Gratitude Journal",
      icon: NotebookIcon,
    },
  ]

  useEffect(() => {
    let animationInterval;
    if (User?.status) {
      const loopOverAnimatedCards = () => {
        setVisiableCtxIndex((previous) => (previous + 1) % contentListAfterLoggIn.length);
      };
       animationInterval = setInterval(loopOverAnimatedCards, 4000);
    } else {
      const loopOverAnimatedCards = () => {
        setVisiableCtxIndex((previous) => (previous + 1) % contentList.length);
         animationInterval = setInterval(loopOverAnimatedCards, 4000);
      };
    }

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  return (
    <>
      <AnimatePresence mode="sync" initial={false}>
        <div className="Subscribe__animatedCardWrapperMob">
          <motion.div
            key={visiableCtxIndex}
            initial={{ x: -450, opacity: 0 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: 280 }}
            transition={{ duration: 0.2, ease: "backInOut" }}
            className="Subscribe__animatedCardMob"
          >
            <div className="Subscribe__IconWrapperMob">
              <img src={contentList[visiableCtxIndex].icon} alt="phone icon" />
            </div>
            <p className="Subscribe__animatedCardParaMob">
              {contentList[visiableCtxIndex].title}
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </>
  );
};
