import React, { useEffect, useState } from "react";
import { useAnimate, motion } from "framer-motion";
import "./ContactUs.scss";
import Navbar from "../Navbar/Navbar";
import { FooterTwo } from "../FooterTwo/FooterTwo";

import ChatWithUs from "../../assets/images/chatWithUs.png";
import ChatWithUs2 from "../../assets/images/ChatUs2.png";
import PhoneIcon from "../../assets/images/PhoneIcon.png";
import PhoneIcon2 from "../../assets/images/CallUs2.png";
import MailIcon from "../../assets/images/MailIcon.png";
import MailIcon2 from "../../assets/images/Mail2.png";
import DownArrowIcon from "../../assets/images/downArrowIcon.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
  const [scope, animate] = useAnimate();

  // code scrolls to the top....
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const firstBgColors = ["#94BAFF", "#94BAFF", "#F0F0F3"];
  const timings = [0.1, 0.5, 1];

  /**
   * The onPageLoadAnimation function in JavaScript React randomly changes the background color of
   * tiles on page load and animates the letters "U" and "p" with specified timings.
   */
  const onPageLoadAnimtion = async () => {
    const minValue = 0;
    const maxValue = 10;

    // randow tile change bg--color on page-load....

    const randomNumber = (min, max) =>
      Math.round(Math.random() * (max - min) + min);

    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.7, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.9, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.7, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.9, times: timings }
    );
    animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.7, times: timings }
    );
    await animate(
      `#col__${randomNumber(minValue, maxValue)} #row__${randomNumber(
        maxValue,
        minValue
      )}`,
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );

    // Up text with animation...

    // for the big "U" ...
    animate(
      "#col__2 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__3 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__5 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__6 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__7 #row__6",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__7 #row__7",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__7 #row__8",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__7 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );

    animate(
      "#col__6 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );

    animate(
      "#col__5 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__3 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__2 #row__9",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );

    animate(
      "#col__2 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__3 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__5 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__6 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__7 #row__11",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );

    animate(
      "#col__2 #row__12",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__2 #row__13",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__2 #row__14",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__3 #row__14",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__14",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__13",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
    animate(
      "#col__4 #row__12",
      { backgroundColor: firstBgColors },
      { duration: 0.8, times: timings }
    );
  };

  useEffect(() => {
    onPageLoadAnimtion();
  }, []);

// Functions to handle receiving email with emailJS
const notify = () => toast.success("Message sent successfully");
const [loading, setLoading] = useState(false);
  const errorMessage = () =>
    toast.error(
      "An error occurred while sending your message. Please try again"
    );

  const [formData, setFormData] = useState({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: ""  
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Send email via EmailJS
    emailjs.send("service_me8vg1i", "template_8xrwb3d", formData, "yhr37czyaD6x0SuJT").then(
      (result) => {
        console.log("Success:", result.text);
        notify();
  
        // Delay for 2 seconds before resetting the form or performing other actions
        setTimeout(() => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: ""
          });
          setLoading(false); // Set loading to false after the delay
        }, 1000); // 2000ms = 2 seconds
  
      },
      (error) => {
        console.error("Error:", error.text);
        errorMessage();
  
        // In case of an error, reset loading after a delay
        setTimeout(() => {
          setLoading(false);
        }, 1000); // Delay of 2 seconds even in case of error
      }
    );
  };
  


  return (
    <>
      <Navbar />
      <div id="contactSEC" className="Contact__outerWrapper">
        {/* grid div start */}
        <div ref={scope} className="Contact__BgGridWrapper">
          {Array(20)
            .join()
            .split(",")
            .map((cur, id) => (
              <div id={`col__${id}`} className="Contact__SevenDiv">
                {Array(20)
                  .join()
                  .split(",")
                  .map((cur, id) => (
                    <div id={`row__${id}`} className="Contact__20" />
                  ))}
              </div>
            ))}
        </div>
        {/* grid div end */}

        <div className="Contact__wrapper">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, ease: "backInOut", delay: 2.5 }}
            className="Contact__mainWrapper"
          >
            <h2 className="Contact__titleH2">Contact our friendly team</h2>
            <p className="Contact__smallPara">Let us know how we can help</p>

            <div className="Contact__InputMainWrapper">
            <form onSubmit={handleSubmit} action="">
              <div className="Contact__InputsWrapper">
                <div>
                  <input className="" 
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required/>
                </div>
                <div>
                  <input className="" 
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name" 
                  required/>
                </div>
              </div>

              <div className="Contact__InputsWrapper">
                <div>
                  <input className="" 
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone number" 
                  required/>
                </div>
                <div>
                  <input className="" 
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email ID" 
                  required/>
                </div>
              </div>

              <div className="Contact__textAreaWrapper">
                <textarea 
                rows="7" 
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Your message here..."></textarea>
              </div>

              <div className="Contact__submitBtnWrapper">
              <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Submit"}
            </button>
              </div>
              </form>
              <ToastContainer style={{ fontSize: '14px', zIndex: '20' }} />
            </div>
          </motion.div>
        </div>

        <a
          href="#git__upwealth"
          className="moveToNewSec__Btn"
          aria-description="move-to-the-next-section-button"
        >
          <img src={DownArrowIcon} alt="downArrowIcon" />
        </a>
      </div>

      <div id="git__upwealth">
        <ContactBottom />
      </div>

      <FooterTwo secID="contactSEC" />
    </>
  );
};

const ContactBottom = () => {
  const [isMouseInside, setIsMouseInside] = useState({
    id: "",
    state: false,
  });

  const [scope, animate] = useAnimate();

  /**
   * The handleClick function changes the appearance of elements on a webpage in response to a mouse
   * click event.
   */
  const handleClick = async (e, id, mainId) => {
    setIsMouseInside({
      id,
      state: true,
    });

    animate(`#${id}`, {
      color: "#C07708",
      transition: {
        duration: 0.8,
        ease: "backOut",
        delay: 1.5,
      },
    });

    await animate(`#${mainId}`, {
      boxShadow: `inset -1px -1px 2px 0px #fff, inset -5px -5px 11.5px 0px #fff,
     inset 1px 1px 2px 0px rgba(0, 0, 0, 0.25),
     inset 5px 5px 8.5px 0px rgba(0, 0, 0, 0.25)`,
      transition: {
        duration: 0.8,
        ease: "backOut",
        delay: 1.5,
      },
    });
  };

  /**
   * The function `handleMouseLeave` in JavaScript React sets the state to indicate mouse leaving,
   * animates color change and box shadow with delays.
   */
  const handleMouseLeave = async (e, id, mainId) => {
    setIsMouseInside({
      id: "",
      state: false,
    });

    animate(`#${id}`, {
      color: "#494949",
      transition: {
        duration: 0.8,
        ease: "backOut",
        delay: 1.5,
      },
    });

    await animate(`#${mainId}`, {
      boxShadow: ` -1px -1px 2px 0px #fff,  -5px -5px 11.5px 0px #fff,
      1px 1px 2px 0px rgba(0, 0, 0, 0.25),
      5px 5px 8.5px 0px rgba(0, 0, 0, 0.25)`,
      transition: {
        duration: 0.8,
        ease: "backOut",
        delay: 1.5,
      },
    });
  };

  return (
    <div className="Conatct__bottomWrapper">
      <div ref={scope} className="Contact__bottomMainDiv">
        <div className="Contact__textWrapper">
          <div className="Contact__h1Wrapper">
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6, ease: "linear", delay: 0.3 }}
              className="Contact__h1"
            >
              Get in touch with Upwealth magazine
            </motion.h1>
          </div>
          <div className="Contact__h1AndParaSeprator" />
          <div className="Contact__paraWrapper">
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8, ease: "linear", delay: 0.6 }}
              className="Contact__para"
            >
              If you have any questions regarding subscriptions, or how to
              feature or advertise in our magazine, don't hesitate to get in
              touch
            </motion.p>
          </div>
        </div>
        <div className="Conatct__BTNWrapper">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: "backInOut", delay: 0.4 }}
            viewport={{ once: true }}
            onClick={(e) => {
              handleClick(e, "Chat__withus", "Contact__leadBtn_1");
            }}
            onTouchStart={(e) => {
              handleClick(e, "Chat__withus", "Contact__leadBtn_1");
            }}
            onMouseLeave={(e) =>
              handleMouseLeave(e, "Chat__withus", "Contact__leadBtn_1")
            }
            onTouchEnd={(e) =>
              handleMouseLeave(e, "Chat__withus", "Contact__leadBtn_1")
            }
            id="Contact__leadBtn_1"
            className="Contact__leadBtn"
          >
            <div className="Contact__chatImgWrapper">
              {isMouseInside.state && isMouseInside.id === "Chat__withus" ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={ChatWithUs2}
                  alt="chatWithUs"
                />
              ) : (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={ChatWithUs}
                  alt="chatWithUs"
                />
              )}
            </div>
            <div className="Contact__chatTextWrapper">
              <h2 id="Chat__withus">
                {isMouseInside.state && isMouseInside.id === "Chat__withus"
                  ? "+1(931-266-6101)"
                  : "Chat with us"}
              </h2>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, ease: "backInOut", delay: 0.4 }}
            onClick={(e) => handleClick(e, "Call__Us", "Contact__leadBtn_2")}
            onTouchStart={(e) =>
              handleClick(e, "Call__Us", "Contact__leadBtn_2")
            }
            onMouseLeave={(e) =>
              handleMouseLeave(e, "Call__Us", "Contact__leadBtn_2")
            }
            onTouchEnd={(e) =>
              handleMouseLeave(e, "Call__Us", "Contact__leadBtn_2")
            }
            className="Contact__leadBtn"
            id="Contact__leadBtn_2"
          >
            <div className="Contact__chatImgWrapper">
              {isMouseInside.state && isMouseInside.id === "Call__Us" ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={PhoneIcon2}
                  alt="PhoneIcon"
                />
              ) : (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={PhoneIcon}
                  alt="PhoneIcon"
                />
              )}
            </div>
            <div className="Contact__chatTextWrapper">
              <h2 id="Call__Us">
                {isMouseInside.state && isMouseInside.id === "Call__Us"
                  ? "+1(931)-266-6424"
                  : "Call us"}
              </h2>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, ease: "backInOut", delay: 0.4 }}
            viewport={{ once: true }}
            onClick={(e) => handleClick(e, "Send__Mail", "Contact__leadBtn_3")}
            onMouseLeave={(e) =>
              handleMouseLeave(e, "Send__Mail", "Contact__leadBtn_3")
            }
            onTouchStart={(e) =>
              handleClick(e, "Send__Mail", "Contact__leadBtn_3")
            }
            onTouchEnd={(e) =>
              handleMouseLeave(e, "Send__Mail", "Contact__leadBtn_3")
            }
            className="Contact__leadBtn"
            id="Contact__leadBtn_3"
          >
            <div className="Contact__chatImgWrapper">
              {isMouseInside.state && isMouseInside.id === "Send__Mail" ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={MailIcon2}
                  alt="MailIcon"
                />
              ) : (
                <motion.img
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.6, ease: "backInOut" }}
                  src={MailIcon}
                  alt="MailIcon"
                />
              )}
            </div>
            <div className="Contact__chatTextWrapper">
              <h2 id="Send__Mail">
                {isMouseInside.state && isMouseInside.id === "Send__Mail"
                  ? "info@upwealthmagazine.com"
                  : "Send an email"}
              </h2>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
