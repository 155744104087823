import React, { useState } from "react";
import { motion, useAnimate } from "framer-motion";
import "./PreviousEdition.scss";
import PreviousEditionBg from "../../assets/images/previousEditionBg.png";
import { Link } from "react-router-dom";

import SliderImg1 from "../../assets/images/sliderImg1.png";
import SliderImg2 from "../../assets/images/sliderImg2.png";
import SliderImg3 from "../../assets/images/sliderImg3.png";
import SliderImg4 from "../../assets/images/sliderImg4.png";
import SliderImg5 from "../../assets/images/sliderImg5.png";
import PreviousEdSlImg from "../../assets/images/PreviousEdSlImg.png";

import DownArrowIcon from "../../assets/images/downArrowIcon.png";
import NextArrowIcon from "../../assets/images/nextArrow.png";
import PreviousArrowIcon from "../../assets/images/preArrow.png";
import ShoppingCartIcon from "../../assets/images/ShoppingCart.png";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

const images = [
  SliderImg3,
  SliderImg4,
  SliderImg5,
  PreviousEdSlImg,
  SliderImg1,
  SliderImg2,
];

const FlipCardImg = ({ startIndex }) => {
  const [cardIndex, setCardIndex] = useState(null);
  const { userInfo } = useSelector((state) => state.user);
  // const User = useSelector((state) => state.user.userInfo);

  const [scope, animate] = useAnimate();

  const handleFlip = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 180,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };
  const handleAnimation = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 0,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };

  const handleClick = () => {
    console.log("handle");
    // navigate("/login");
  };

  return (
    <>
      <div className="PreviousEdition__sliderDiv">
        <div className="image-slider">
          {userInfo ? (
            <div ref={scope} className="image-slider-container">
              {images.map((image, index) => {
                return (
                  <div key={index} className="image-card">
                    <motion.div
                      onClick={(e) => handleFlip(e, index)}
                      onMouseLeave={(e) => handleAnimation(e, index)}
                      style={{
                        perspective: 1000,
                        position: "relative",
                      }}
                      id="CardsContainer"
                    >
                      <motion.div
                        id={`card__${index}`}
                        style={{
                          transformStyle: "preserve-3d",
                          backfaceVisibility: "hidden",
                          rotateY: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <motion.img
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ duration: 0.8, ease: "backIn" }}
                          src={image}
                          alt={`Image ${startIndex + index}`}
                          style={{
                            zIndex: cardIndex === index ? -1 : 1,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <motion.div
                          id="Btn__Wrapper"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            zIndex: cardIndex === index ? 1 : -1,
                            rotateY: 180,
                            background: "#F0F0F3",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4rem",
                          }}
                          onClick={() => handleClick()}
                        >
                          {/* <motion.button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                            border: "none",
                            padding: "1.5rem 3rem",
                            borderRadius: "1.2rem",
                            cursor: "pointer",
                            background: "#F0F0F3",
                            zIndex: 2,
                          }}
                          initial={{
                            boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          whileHover={{
                            boxShadow: `inset -0.75px -0.75px 1.5px 0px #FFF,
                             inset -3.75px -3.75px 8.625px 0px #FFF,
                             inset  0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                             inset   3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          transition={{ duration: 0.4, ease: "linear" }}
                        >
                          <span
                            style={{
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Order Your Hard Copy
                          </span>
                          <img
                            style={{
                              width: "2.5rem",
                            }}
                            src={ShoppingCartIcon}
                            alt="cart-icon"
                          />
                        </motion.button> */}
                          {userInfo ? (
                            <Link
                              to="/"
                              // onClick={()=>handleClick('view')}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              View
                            </Link>
                          ) : (
                            <Link
                              to="/login"
                              onClick={() => handleClick("login")}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              Log In
                            </Link>
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div ref={scope} className="image-slider-container">
              {images.slice(startIndex, startIndex + 4).map((image, index) => {
                return (
                  <div key={index} className="image-card">
                    <motion.div
                      onClick={(e) => handleFlip(e, index)}
                      onMouseLeave={(e) => handleAnimation(e, index)}
                      style={{
                        perspective: 1000,
                        position: "relative",
                      }}
                      id="CardsContainer"
                    >
                      <motion.div
                        id={`card__${index}`}
                        style={{
                          transformStyle: "preserve-3d",
                          backfaceVisibility: "hidden",
                          rotateY: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <motion.img
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ duration: 0.8, ease: "backIn" }}
                          src={image}
                          alt={`Image ${startIndex + index}`}
                          style={{
                            zIndex: cardIndex === index ? -1 : 1,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <motion.div
                          id="Btn__Wrapper"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            zIndex: cardIndex === index ? 1 : -1,
                            rotateY: 180,
                            background: "#F0F0F3",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4rem",
                          }}
                          onClick={() => handleClick()}
                        >
                          {/* <motion.button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                            border: "none",
                            padding: "1.5rem 3rem",
                            borderRadius: "1.2rem",
                            cursor: "pointer",
                            background: "#F0F0F3",
                            zIndex: 2,
                          }}
                          initial={{
                            boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          whileHover={{
                            boxShadow: `inset -0.75px -0.75px 1.5px 0px #FFF,
                             inset -3.75px -3.75px 8.625px 0px #FFF,
                             inset  0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                             inset   3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          transition={{ duration: 0.4, ease: "linear" }}
                        >
                          <span
                            style={{
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Order Your Hard Copy
                          </span>
                          <img
                            style={{
                              width: "2.5rem",
                            }}
                            src={ShoppingCartIcon}
                            alt="cart-icon"
                          />
                        </motion.button> */}
                          {userInfo ? (
                            <Link
                              to="/"
                              // onClick={()=>handleClick('view')}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              View
                            </Link>
                          ) : (
                            <Link
                              to="/login"
                              onClick={() => handleClick("login")}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              Log In
                            </Link>
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const FlipCardImgTab = ({ startIndex }) => {
  const [cardIndex, setCardIndex] = useState(null);
  const User = useSelector((state) => state.user.userInfo);

  const [scope, animate] = useAnimate();

  const handleFlip = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 180,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };
  const handleAnimation = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 0,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };

  return (
    <>
      <div className="PreviousEdition__sliderDiv">
        <div className="image-slider">
        {
          User?.status?(
            <div ref={scope} className="image-slider-container">
            {images.map((image, index) => {
              return (
                <div key={index} className="image-card">
                  <motion.div
                    onClick={(e) => handleFlip(e, index)}
                    onMouseLeave={(e) => handleAnimation(e, index)}
                    style={{
                      perspective: 1000,
                      position: "relative",
                    }}
                    id="CardsContainer"
                  >
                    <motion.div
                      id={`card__${index}`}
                      style={{
                        transformStyle: "preserve-3d",
                        backfaceVisibility: "hidden",
                        rotateY: 0,
                        width: "100%",
                        height: "100%",
                        aspectRatio: "4/5",
                      }}
                    >
                      <motion.img
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.8, ease: "backIn" }}
                        src={image}
                        alt={`Image ${startIndex + index}`}
                        style={{
                          zIndex: cardIndex === index ? -1 : 1,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <motion.div
                        id="Btn__Wrapper"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          zIndex: cardIndex === index ? 1 : -1,
                          rotateY: 180,
                          background: "#F0F0F3",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4rem",
                        }}
                        // onClick={() => handleClick()}
                      >
                        {/* <motion.button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                            border: "none",
                            padding: "1.5rem 3rem",
                            borderRadius: "1.2rem",
                            cursor: "pointer",
                            background: "#F0F0F3",
                            zIndex: 2,
                          }}
                          initial={{
                            boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          whileHover={{
                            boxShadow: `inset -0.75px -0.75px 1.5px 0px #FFF,
                             inset -3.75px -3.75px 8.625px 0px #FFF,
                             inset  0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                             inset   3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          transition={{ duration: 0.4, ease: "linear" }}
                        >
                          <span
                            style={{
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Order Your Hard Copy
                          </span>
                          <img
                            style={{
                              width: "2.5rem",
                            }}
                            src={ShoppingCartIcon}
                            alt="cart-icon"
                          />
                        </motion.button> */}

                        {User?.status ? (
                          <Link
                            to="/"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "1rem",
                              border: "none",
                              boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                              padding: "1.5rem 3rem",
                              borderRadius: "1.2rem",
                              cursor: "pointer",
                              background: "#F0F0F3",
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                              textDecoration: "none",
                            }}
                          >
                            View
                          </Link>
                        ) : (
                          <Link
                            to="/login"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "1rem",
                              border: "none",
                              boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                              padding: "1.5rem 3rem",
                              borderRadius: "1.2rem",
                              cursor: "pointer",
                              background: "#F0F0F3",
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Log In
                          </Link>
                        )}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </div>
              );
            })}
          </div>
          ):(
            <div ref={scope} className="image-slider-container">
            {images.slice(startIndex, startIndex + 2).map((image, index) => {
              return (
                <div key={index} className="image-card">
                  <motion.div
                    onClick={(e) => handleFlip(e, index)}
                    onMouseLeave={(e) => handleAnimation(e, index)}
                    style={{
                      perspective: 1000,
                      position: "relative",
                    }}
                    id="CardsContainer"
                  >
                    <motion.div
                      id={`card__${index}`}
                      style={{
                        transformStyle: "preserve-3d",
                        backfaceVisibility: "hidden",
                        rotateY: 0,
                        width: "100%",
                        height: "100%",
                        aspectRatio: "4/5",
                      }}
                    >
                      <motion.img
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.8, ease: "backIn" }}
                        src={image}
                        alt={`Image ${startIndex + index}`}
                        style={{
                          zIndex: cardIndex === index ? -1 : 1,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <motion.div
                        id="Btn__Wrapper"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          zIndex: cardIndex === index ? 1 : -1,
                          rotateY: 180,
                          background: "#F0F0F3",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4rem",
                        }}
                        // onClick={() => handleClick()}
                      >
                        {/* <motion.button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                            border: "none",
                            padding: "1.5rem 3rem",
                            borderRadius: "1.2rem",
                            cursor: "pointer",
                            background: "#F0F0F3",
                            zIndex: 2,
                          }}
                          initial={{
                            boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          whileHover={{
                            boxShadow: `inset -0.75px -0.75px 1.5px 0px #FFF,
                             inset -3.75px -3.75px 8.625px 0px #FFF,
                             inset  0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                             inset   3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                          }}
                          transition={{ duration: 0.4, ease: "linear" }}
                        >
                          <span
                            style={{
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Order Your Hard Copy
                          </span>
                          <img
                            style={{
                              width: "2.5rem",
                            }}
                            src={ShoppingCartIcon}
                            alt="cart-icon"
                          />
                        </motion.button> */}

                        {User?.status ? (
                          <Link
                            to="/"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "1rem",
                              border: "none",
                              boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                              padding: "1.5rem 3rem",
                              borderRadius: "1.2rem",
                              cursor: "pointer",
                              background: "#F0F0F3",
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                              textDecoration: "none",
                            }}
                          >
                            View
                          </Link>
                        ) : (
                          <Link
                            to="/login"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "1rem",
                              border: "none",
                              boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                              padding: "1.5rem 3rem",
                              borderRadius: "1.2rem",
                              cursor: "pointer",
                              background: "#F0F0F3",
                              color: "#202020",
                              fontFamily: "Montserrat",
                              fontSize: "1.2rem",
                              fontWeight: 700,
                              fontStyle: "normal",
                              lineHeight: "normal",
                            }}
                          >
                            Log In
                          </Link>
                        )}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </div>
              );
            })}
          </div>
          )
        }
          
        </div>
      </div>
    </>
  );
};

const FlipCardImgMob = ({ startIndex }) => {
  const [cardIndex, setCardIndex] = useState(null);
  const User = useSelector((state) => state.user.userInfo);

  const [scope, animate] = useAnimate();

  const handleFlip = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 180,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };
  const handleAnimation = (e, id) => {
    animate(
      `.image-card #CardsContainer #card__${id}`,
      {
        rotateY: 0,
      },
      {
        transition: {
          duration: 0.5,
        },
      }
    );
  };

  return (
    <>
      <div className="PreviousEdition__sliderDiv">
        <div className="image-slider">
          {User?.status ? (
            <div ref={scope} className="image-slider-containerLoggin">
              {images.map((image, index) => {
                return (
                  <div key={index} className="image-card">
                    <motion.div
                      onClick={(e) => handleFlip(e, index)}
                      onMouseLeave={(e) => handleAnimation(e, index)}
                      style={{
                        perspective: 1000,
                        position: "relative",
                      }}
                      id="CardsContainer"
                    >
                      <motion.div
                        id={`card__${index}`}
                        style={{
                          transformStyle: "preserve-3d",
                          backfaceVisibility: "hidden",
                          rotateY: 0,
                          width: "100%",
                          height: "100%",
                          aspectRatio: "4/5",
                        }}
                      >
                        <motion.img
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ duration: 0.8, ease: "backIn" }}
                          src={image}
                          alt={`Image ${startIndex + index}`}
                          style={{
                            zIndex: cardIndex === index ? -1 : 1,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <motion.div
                          id="Btn__Wrapper"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            zIndex: cardIndex === index ? 1 : -1,
                            rotateY: 180,
                            background: "#F0F0F3",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4rem",
                          }}
                          // onClick={() => handleClick()}
                        >
                          {User?.status ? (
                            <Link
                              to="/"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              View
                            </Link>
                          ) : (
                            <Link
                              to="/login"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              Log In
                            </Link>
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div ref={scope} className="image-slider-container">
              {images.slice(startIndex, startIndex + 1).map((image, index) => {
                return (
                  <div key={index} className="image-card">
                    <motion.div
                      onClick={(e) => handleFlip(e, index)}
                      onMouseLeave={(e) => handleAnimation(e, index)}
                      style={{
                        perspective: 1000,
                        position: "relative",
                      }}
                      id="CardsContainer"
                    >
                      <motion.div
                        id={`card__${index}`}
                        style={{
                          transformStyle: "preserve-3d",
                          backfaceVisibility: "hidden",
                          rotateY: 0,
                          width: "100%",
                          height: "100%",
                          aspectRatio: "4/5",
                        }}
                      >
                        <motion.img
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ duration: 0.8, ease: "backIn" }}
                          src={image}
                          alt={`Image ${startIndex + index}`}
                          style={{
                            zIndex: cardIndex === index ? -1 : 1,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <motion.div
                          id="Btn__Wrapper"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            zIndex: cardIndex === index ? 1 : -1,
                            rotateY: 180,
                            background: "#F0F0F3",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4rem",
                          }}
                          // onClick={() => handleClick()}
                        >
                          {User?.status ? (
                            <Link
                              to="/"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              View
                            </Link>
                          ) : (
                            <Link
                              to="/login"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                border: "none",
                                boxShadow: `-0.75px -0.75px 1.5px 0px #FFF,
                             -3.75px -3.75px 8.625px 0px #FFF,
                              0.75px 0.75px 1.5px 0px rgba(0, 0, 0, 0.25),
                               3.75px 3.75px 6.375px 0px rgba(0, 0, 0, 0.25)`,
                                padding: "1.5rem 3rem",
                                borderRadius: "1.2rem",
                                cursor: "pointer",
                                background: "#F0F0F3",
                                color: "#202020",
                                fontFamily: "Montserrat",
                                fontSize: "1.2rem",
                                fontWeight: 700,
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textDecoration: "none",
                              }}
                            >
                              Log In
                            </Link>
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const PreviousEdition = () => {
  const [startIndex, setStartIndex] = useState(0);
  const User = useSelector((state) => state.user?.userInfo);

  const handleNextClick = () => {
    const nextIndex = startIndex + 4;
    if (nextIndex < images.length) {
      setStartIndex(nextIndex);
    }
  };

  const handlePrevClick = () => {
    const prevIndex = startIndex - 4;
    if (prevIndex >= 0) {
      setStartIndex(prevIndex);
    }
  };

  const handleNextClickTab = () => {
    const nextIndex = startIndex + 2;
    if (nextIndex < images.length) {
      setStartIndex(nextIndex);
    }
  };

  const handlePrevClickTab = () => {
    const prevIndex = startIndex - 2;
    if (prevIndex >= 0) {
      setStartIndex(prevIndex);
    }
  };

  const handleNextClickMob = () => {
    const nextIndex = startIndex + 1;
    if (nextIndex < images.length) {
      setStartIndex(nextIndex);
    }
  };
  const handlePrevClickMob = () => {
    const prevIndex = startIndex - 1;
    if (prevIndex >= 0) {
      setStartIndex(prevIndex);
    }
  };

  return (
    <div className="PreviousEdition__outerWrapper">
      <motion.img
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "backOut", delay: 0.4 }}
        viewport={{ once: true }}
        className="previousEditionBg"
        src={PreviousEditionBg}
        alt="Previous-Edition-background-Image"
      />

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "backOut", delay: 0.5 }}
        className="PreviousEdition__mainWrapper"
      >
        <div className="PreviousEdition__topOuterWrapper">
          <div className="PreviousEdition__topDivLeftWrapper">
            <motion.h3
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "backOut", delay: 0.5 }}
              viewport={{ once: true }}
              className="PreviousEdition__topDivSubtitle"
            >
              Previous editions
            </motion.h3>
            <motion.h2
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1.5, ease: "backOut", delay: 0.8 }}
              viewport={{ once: true }}
              className="PreviousEdition__topDivTitle"
            >
              Explore all the magazine issues available for purchase.
            </motion.h2>
          </div>
          {!User?.status && (
            <div className="PreviousEdition__topDivRightWrapper">
              <motion.button
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "backOut", delay: 0.1 }}
                viewport={{ once: true }}
                className="PreviousEdition__viewAllBtn"
              >
                View All Editions 
              </motion.button>
            </div>
          )}
        </div>

        <div className="PreviousEdition__topOuterWrapperTab">
          <div className="PreviousEdition__topDivLeftWrapperTab">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "backOut", delay: 0.5 }}
              viewport={{ once: true }}
              className="PreviousEdition__topDivSubtitleWrapperTab"
            >
              <h3>Previous editions</h3>
            </motion.div>
            <div className="PreviousEdition__seperatorDivTab" />
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1.5, ease: "backOut", delay: 0.8 }}
              viewport={{ once: true }}
              className="PreviousEdition__topDivTitleWrapperTab"
            >
              <h2>Explore all the magazine issues available for purchase.</h2>
            </motion.div>
          </div>
          <div className="PreviousEdition__topDivRightWrapperTab">
            {!User?.status && (
              <>
                <div className="PreviousEdition__sliderNavWrapper">
                  <button
                    disabled={startIndex === 0}
                    onClick={handlePrevClickTab}
                    className="navigationArrowBtns"
                  >
                    <img src={PreviousArrowIcon} alt="previous-arrow-icon" />
                  </button>

                  <button
                    disabled={startIndex + 2 >= images.length}
                    onClick={handleNextClickTab}
                    className="navigationArrowBtns"
                  >
                    <img src={NextArrowIcon} alt="previous-arrow-icon" />
                  </button>
                </div>

                <div className="PreviousEdition__ViewMoreBtnWrapperTab">
                  <motion.button
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.6, ease: "backOut", delay: 0.1 }}
                    viewport={{ once: true }}
                    className="PreviousEdition__viewAllBtn"
                  >
                    View All Editions
                  </motion.button>
                </div>
              </>
            )}
          </div>

          <div className="PreviousEdition__topDivRightWrapperMob">
            {!User?.status && (
              <>
                <div className="PreviousEdition__sliderNavWrapper">
                  <button
                    disabled={startIndex === 0}
                    onClick={handlePrevClickMob}
                    className="navigationArrowBtns"
                  >
                    <img src={PreviousArrowIcon} alt="previous-arrow-icon" />
                  </button>
                  <button
                    disabled={startIndex + 1 >= images.length}
                    onClick={handleNextClickMob}
                    className="navigationArrowBtns"
                  >
                    <img src={NextArrowIcon} alt="previous-arrow-icon" />
                  </button>
                </div>

                <div className="PreviousEdition__ViewMoreBtnWrapperTab">
                  <motion.button
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.6, ease: "backOut", delay: 0.1 }}
                    viewport={{ once: true }}
                    className="PreviousEdition__viewAllBtn"
                  >
                    View All Editions
                  </motion.button>
                </div>
              </>
            )}
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "backOut", delay: 0.3 }}
          viewport={{ once: true }}
          className="PreviousEdition__outerBottomWrapper"
        >
          {!User?.status && (
            <button
              className="navigationArrowBtns navigationArrowBtn__Previous"
              disabled={startIndex === 0}
              onClick={handlePrevClick}
            >
              <img src={PreviousArrowIcon} alt="previous-arrow-icon" />
            </button>
          )}

          <FlipCardImg startIndex={startIndex} />

          {!User?.status && (
            <button
              className="navigationArrowBtns navigationArrowBtn__Next"
              disabled={startIndex + 4 >= images.length}
              onClick={handleNextClick}
            >
              <img src={NextArrowIcon} alt="next-arrow-icon" />
            </button>
          )}
        </motion.div>

        <div className="PreviousEdition__outerBottomWrapperTab">
          <FlipCardImgTab startIndex={startIndex} />
        </div>
        <div className="PreviousEdition__outerBottomWrapperMob">
          <FlipCardImgMob startIndex={startIndex} />
        </div>

        <div className="PreviousEdition__nextSectionBtnWrapper">
          <a href="#subscriberBenifits" className="moveToNewSec__Btn">
            <img src={DownArrowIcon} alt="downArrowIcon" />
          </a>
        </div>
        {!User?.status && (
          <div className="PreviousEdition__ViewMoreBtnWrapperMob">
            <motion.button
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "backOut", delay: 0.1 }}
              viewport={{ once: true }}
              className="PreviousEdition__viewAllBtn"
            >
              View All Editions
            </motion.button>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default PreviousEdition;
